import React from 'react';

import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { styles } from './ContractAdvice.style';

function ContractAdvice(props: WithStyles<typeof styles>) {
    const { classes } = props;
    const mobileStart = useMediaQuery('(max-width:749px)');

    return (
        <div>
            <Grid container className={classes.subGridTitle}>
                <Grid item xs={12}>
                    <Box className={classes.titleContainer}>
                        <p className={classes.title}>AVISO DE MODIFICACIÓN</p>
                        <p id="bot" className={classes.title}>
                            EN TU CONTRATO DE ADHESIÓN
                        </p>
                    </Box>

                    <Box className={classes.section}>
                        <p className={classes.text}>
                            Cuenta de Fondos de Pago Electrónico RECA NÚM: 15717-458-035582/03-01762-0724
                        </p>
                    </Box>
                    <Box className={classes.section}>
                        <p className={classes.text}>
                            En cumplimiento a lo establecido en las Disposiciones de Carácter General de la CONDUSEF en
                            materia de transparencia y sanas prácticas aplicables a las instituciones de tecnología
                            financiera, te notificamos que en 30 días naturales posteriores a que recibas esta
                            notificación entrarán en vigor ciertas modificaciones, las cuales se resumen más adelante,
                            realizadas a tu Contrato de adhesión de emisión y depósito mercantil de fondos de pago
                            electrónico que celebraste cuenca tecnología financiera, S.A. de C.V., Institución de fondos
                            de pago electrónico, en adelante “Cuenca”.
                        </p>
                        <p className={classes.text}>
                            El contrato mencionado se encuentra debidamente registrado en el Registro de Contratos de
                            adhesión de la CONDUSEF (“RECA”) con el número 15717-458-035582/03-01762-0724.
                        </p>
                        <p className={classes.text}>
                            <u>
                                Resumen de los últimos cambios realizados al Contrato de Adhesión: Vigentes a partir del
                                18 de agosto de 2024: 15717-458-035582/03-01762-0724.
                            </u>
                        </p>
                        <p className={classes.text}>
                            Recuerda que dentro de los 30 días posteriores a que recibas esta notificación podrá dar por
                            terminado tu contrato sin responsabilidad alguna a tu cargo, y bajo las condiciones pactadas
                            previamente, debiendo cubrir, en su caso, los adeudos que se generen hasta el término de tu
                            Cuenta, sin que se te cobre alguna penalización por dicha cancelar.
                        </p>
                        <p className={classes.text}>
                            A continuación podrás revisar los cambios que se realizaron a tu contrato, de igual forma
                            podrás consultar dichos documentos dentro de la sección de legales en esta página o en tu
                            Aplicación, también podrás consultarlos cambios en el portal RECA en{' '}
                            <em>www.condusef.gob.mx</em>
                        </p>
                    </Box>
                </Grid>
            </Grid>

            <Grid container className={classes.subGridClauses}>
                <Grid item xs={mobileStart ? 12 : 4}>
                    <p className={classes.subtitle}>Cláusulas Modificadas</p>
                </Grid>
                <Grid item xs={mobileStart ? 12 : 8}>
                    <p className={classes.text}>
                        <strong>Cláusula Primera y Carátula:</strong> Se incluye la leyenda que la Carátula forma parte
                        integrante del Contrato de Adhesión.
                    </p>
                    <p className={classes.text}>
                        <strong>Cláusula Sexta:</strong> Se agrega la obligación de Cuenca que tiene de reembolsar al
                        Cliente, cuando éste así lo solicite, la cantidad de moneda nacional equivalentes al valor de
                        los fondos de pago electrónico emitidos de que dicho usuario disponga en los registros
                        respectivos, siempre y cuando tales fondos de pago electrónico no sean parte de una orden de
                        pago en ejecución, de conformidad con lo pactado.
                    </p>
                    <p className={classes.text}>
                        <strong>Cláusula Décima Sexta:</strong> Se incluye la posibilidad de presentar aviso por
                        defunción del Cliente, así como el momento a partir del cual cesa la responsabilidad del
                        Cliente.
                    </p>
                    <p className={classes.text}>
                        <strong>Cláusula Vigésima Segunda:</strong> Se agrega que Cuenca obtendrá el consentimiento
                        expreso del usuario en forma independiente del contrato, previa autorización de éste, para
                        recibir publicidad o que su información sea utilizada para fines mercadotécnicos, publicitarios
                        o de cualquier otra índole de conformidad con lo señalado en el artículo 11 primer párrafo de
                        las Disposiciones de carácter general de la CONDUSEF en materia de transparencia y sanas
                        prácticas aplicables a las instituciones de tecnología.
                    </p>
                    <p className={classes.text}>
                        <strong>Cláusula Décima Quinta:</strong> Se incluye el concepto y el monto de cada una de las
                        comisiones que le cobrará al usuario, así como cada uno de los eventos que las generen,
                        especificando su periodicidad.
                    </p>
                    <p className={classes.text}>
                        <strong>Cláusula Vigésima Cuarta:</strong> Se especifican los motivos por los cuales la entidad
                        puede cancelar la Cuenta.
                    </p>
                    <p className={classes.text}>
                        <strong>Cláusula Vigésima Cuarta:</strong> Se especifica que Cuenca dará aviso al Cliente con
                        treinta días naturales de anticipación para la modificación del contrato lo hará a través de la
                        Aplicación Cuenca.
                    </p>
                    <p className={classes.text}>
                        <strong>Cláusula Vigésima Quinta:</strong> Se agrega que Cuenca proporcionará al usuario de
                        manera gratuita información para evitar posibles riesgos tecnológicos, cibernéticos y de fraude
                        en los productos y servicios financieros contratados, tales como el envío de notificaciones de
                        operaciones inusuales vía telefónica, mensajes de texto SMS o correo electrónico, entre otros.
                    </p>
                    <p className={classes.text}>
                        <strong>Cláusula Trigésima Tercera:</strong> Se agrega la obligación de Cuenca de contar con
                        ejemplares vigentes de los formatos de los Contratos de Adhesión registrados en el RECA
                        disponibles para su consulta e impresión a través de la Aplicación.
                    </p>
                    <p className={classes.text}>
                        <strong>Cláusula Trigésima Cuarta:</strong> Se incluyen los requisitos para realizar consultas
                        de saldos, transacciones y movimientos, así como los lugares y medios pactados para realizarlas.
                    </p>
                    <p className={classes.text}>
                        <strong>Anexo G:</strong> Se agrega anexo, el cual incluye una transcripción de las referencias
                        de los artículos a los que hace referencia el Contrato de Adhesión.
                    </p>
                    <p className={classes.text}>
                        <strong>Carátula:</strong>
                    </p>
                    <p className={classes.text}>- Se agrega el nombre comercial del producto.</p>
                    <p className={classes.text}>- Se incluye la sección de seguros.</p>
                    <p className={classes.text}>- Se especifica que no existe la opción de retiros en efectivos.</p>
                    <p className={classes.text}>
                        - Se especifican los lugares donde el Cliente puede consultar los Estados de Cuenta.
                    </p>
                    <p className={classes.text}>
                        - Se especifica la cláusula del Contrato de Adhesión que hace mención a las comisiones.
                    </p>
                    <p className={classes.text}>- Se elimina la referencia a lugares para retiro de efectivo.</p>

                    <p className={classes.clause}>
                        <strong>
                            Cambios realizados mediante el contrato con número RECA: 15717-458-035582/02-03348-1223 en
                            enero 2024
                        </strong>
                    </p>

                    <p className={classes.text}>Inclusión de nuevas comisiones al Anexo Comisiones:</p>

                    <p className={classes.text}>
                        <strong>Previas</strong>
                    </p>
                    <p className={classes.text}>- Manejo de Cuenta: Gratis</p>
                    <p className={classes.text}>
                        - Comisión por transferencia: las primeras 10 gratis, $8.62 más IVA cada una a partir de la
                        transferencia número 11 realizada en un mes calendario hacia una cuenta abierta en una Entidad
                        Financiera distinta a Cuenca.
                    </p>

                    <p className={classes.text}>
                        <strong>Vigentes</strong>
                    </p>
                    <p className={classes.text}>
                        - Manejo de Cuenta: ($43.10 + IVA) mensuales por concepto de manejo de cuenta.
                    </p>
                    <p className={classes.text}>
                        - Comisión por transferencia: las primeras 15 gratis, ($8.62 más IVA) cada una a partir de la
                        transferencia número 11 realizada en un mes calendario hacia una cuenta abierta en una Entidad
                        Financiera distinta a Cuenca.
                    </p>
                </Grid>
            </Grid>
        </div>
    );
}

export default withStyles(styles)(ContractAdvice);
